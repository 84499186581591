import './statsArea.css'
import { BarChart } from '@mui/x-charts';

export default function StatsArea(props: any) {
    const totalGames = props.pixdleData["winloss"]["total"]
    const wins = props.pixdleData["winloss"]["win"]
    let percentage = 0
    if (totalGames > 0){
        percentage = Math.round((wins/totalGames) * 100)
    }
    const chartData = props.pixdleData["winloss"]["spread"]
    const axisLabels = [1, 2, 3, 4, 5, 6]


    return (
        <div className='StatsDiv'>
            <div className='exitStats not-selectable'>
                <h1 onClick={()=>{props.dispatch({type: "EXIT_STATS"})}}><b>X</b></h1>
            </div>
            <div className='statsTitleDiv'>
                <b className='statsTitle not-selectable'><ins>Statistics</ins></b>
            </div>
            <div className='overallStats'>
                <div className='statTotals'>
                    <p className='statTotalNumber not-selectable'><b>{totalGames}</b></p>
                    <p className='statTotalDescription not-selectable'>Played</p>
                </div>
                <div className='statTotals'>
                    <p className='statTotalNumber not-selectable'><b>{percentage}%</b></p>
                    <p className='statTotalDescription not-selectable'>Win %</p>
                </div>
            </div>
            <div className='guessChartDiv'>
                <div className='guessChartTitleDiv'>
                    <b className='statsTitle not-selectable'><ins>Guess Distribution</ins></b>
                </div>
                <div className='barChartDiv'>
                    <BarChart
                        sx={{
                            //change left axis label styles
                            "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel":{
                                strokeWidth:"0.4",
                                fill:"#FFFFFF"
                            },
                            // leftAxis Line Styles
                            "& .MuiChartsAxis-left .MuiChartsAxis-line":{
                            stroke:"#FFFFFF",
                            strokeWidth:0.4
                            },
                            // leftAxis tick Styles
                            "& .MuiChartsAxis-left .MuiChartsAxis-tick":{
                                stroke:"#FFFFFF",
                                strokeWidth:0.4
                            }}}
                        series={[
                            { data: chartData, label: 'guesses', id: 'guessId', color:"#234020" },
                        ]}
                        yAxis={[{ data: axisLabels, scaleType: 'band'}]}
                        axisHighlight={{x:"none", y:"none"}}
                        layout='horizontal'
                        bottomAxis={{disableLine: true}}
                        disableAxisListener={true}
                        slotProps={{legend:{hidden: true}}}
                    />
                </div>
               
            </div>
            
        

        </div>
    )
}