import './howToPlay.css'
import greenTileFinal from '../../images/greenTileFinal.png'
import yellowTileFinal from '../../images/yellowTileFinal.png'
import fullyCorrectFinal from '../../images/fullyCorrectFinal.png'

export default function HowToPlay(props: any) {
    return (
        <div className='HowToPlayDiv'>
            <div className='exitHowTo not-selectable'>
                <h1 onClick={()=>{props.exitHowToBox()}}><b>X</b></h1>
            </div>
            <div className='rulesDiv'>
                <b className='howToPlayTitle not-selectable'>How to Play</b>
                <ul className='not-selectable'>
                    <li className='bulletText'>Guess the object in the pixelated image in 6 tries.</li>
                    <li className='bulletText'>The pixelated image will become clearer after each guess.</li>
                    <li className='bulletText'>The letter tiles will change color to show how close your guess is.</li>
                    <li className='bulletText'>The answer will be up to 12 letters.</li>
                    <li className='bulletText'>A bar will appear under your guess to show if the guess was shorter or longer than the answer.</li>
                </ul>
             </div>
             <div className='examplesDiv'>
                <b className='examplesTitle not-selectable'>Examples</b>
                <div className='examplesExplain'>
                    <div className='examplesExplainImages'>
                        <p className="not-selectable bulletText" style={{margin:" 0 0 0 12px" }}>Example Answer: Bear</p>
                        <img className="examplesExplainImagesObject" src={greenTileFinal} alt="Green Tile"/>
                        <img className="examplesExplainImagesObject" style={{margin:" 0 0 0 6px" }} src={yellowTileFinal} alt="Yellow Tile"/>
                        <img className="examplesExplainImagesObject" style={{margin:" 0 0 0 5px" }} src={fullyCorrectFinal} alt="Correct Answer"/>
                    </div>
                    <div className='examplesExplainText'>
                        <b className='examplesSubtitles not-selectable'>Tiles</b>
                            <ul className='not-selectable'>
                                <li className='bulletText'>Green tiles = letter is in the correct position.</li>
                                <li className='bulletText'>Yellow tiles = letter is in the word, but in another position.</li>
                                <li className='bulletText'>Gray tiles = letter is not in the word.</li>
                            </ul>
                        <b className='examplesSubtitles not-selectable'>Bars</b>
                            <ul className='not-selectable'>
                                <li className='bulletText'>Green bar = guess is the correct length.</li>
                                <li className='bulletText'>Orange bar = guess is shorter than the answer.</li>
                                <li className='bulletText'>Red bar = guess is longer than the answer.</li>
                            </ul>    
                    </div>
                </div>
             </div>

        </div>
    )
}