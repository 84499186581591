import { ImagePixelated } from 'react-pixelate';
import './imageArea.css';
import PreviousGuess from '../input/previousGuess';
import { memo, useEffect, useMemo, useState } from 'react';

 const ImageArea = memo(function ImageArea(props: any) {

    const pixelSizeLevels = useMemo(() => {
        return [165, 120, 90, 60, 30, 15]
    },[]);

    const [imagePixelated, setImagePixelated] = useState(
        [
            <ImagePixelated key={"image"+props.selectedPixdlePuzzleNumber+"guess1pixel"+180}
                            src={require(`../../dailyData/${props.selectedPixdlePuzzleNumber}/todaysImage.jpg`)} 
                            pixelSize={pixelSizeLevels[0]}
            />,
            <ImagePixelated key={"image"+props.selectedPixdlePuzzleNumber+"guess2pixel"+140}
                            src={require(`../../dailyData/${props.selectedPixdlePuzzleNumber}/todaysImage.jpg`)} 
                            pixelSize={pixelSizeLevels[1]}
            />,
            <ImagePixelated key={"image"+props.selectedPixdlePuzzleNumber+"guess3pixel"+100}
                            src={require(`../../dailyData/${props.selectedPixdlePuzzleNumber}/todaysImage.jpg`)} 
                            pixelSize={pixelSizeLevels[2]}
            />, 
            <ImagePixelated key={"image"+props.selectedPixdlePuzzleNumber+"guess4pixel"+70}
                            src={require(`../../dailyData/${props.selectedPixdlePuzzleNumber}/todaysImage.jpg`)} 
                            pixelSize={pixelSizeLevels[3]}
            />,
            <ImagePixelated key={"image"+props.selectedPixdlePuzzleNumber+"guess5pixel"+40}
                            src={require(`../../dailyData/${props.selectedPixdlePuzzleNumber}/todaysImage.jpg`)} 
                            pixelSize={pixelSizeLevels[4]}
            />,
            <ImagePixelated key={"image"+props.selectedPixdlePuzzleNumber+"guess6pixel"+15}
                            src={require(`../../dailyData/${props.selectedPixdlePuzzleNumber}/todaysImage.jpg`)} 
                            pixelSize={pixelSizeLevels[5]}
            />,
            <ImagePixelated key={"image"+props.selectedPixdlePuzzleNumber+"cleanpixel0"}
                            src={require(`../../dailyData/${props.selectedPixdlePuzzleNumber}/todaysImage.jpg`)} 
                            pixelSize={0}
            />                                                                                                           
        ])     
    
    useEffect(()=>{
        setImagePixelated(
            [
                <ImagePixelated key={"image"+props.selectedPixdlePuzzleNumber+"guess1pixel"+180}
                                src={require(`../../dailyData/${props.selectedPixdlePuzzleNumber}/todaysImage.jpg`)} 
                                pixelSize={pixelSizeLevels[0]}
                />,
                <ImagePixelated key={"image"+props.selectedPixdlePuzzleNumber+"guess2pixel"+140}
                                src={require(`../../dailyData/${props.selectedPixdlePuzzleNumber}/todaysImage.jpg`)} 
                                pixelSize={pixelSizeLevels[1]}
                />,
                <ImagePixelated key={"image"+props.selectedPixdlePuzzleNumber+"guess4pixel"+100}
                                src={require(`../../dailyData/${props.selectedPixdlePuzzleNumber}/todaysImage.jpg`)} 
                                pixelSize={pixelSizeLevels[2]}
                />, 
                <ImagePixelated key={"image"+props.selectedPixdlePuzzleNumber+"guess4pixel"+70}
                                src={require(`../../dailyData/${props.selectedPixdlePuzzleNumber}/todaysImage.jpg`)} 
                                pixelSize={pixelSizeLevels[3]}
                />,
                <ImagePixelated key={"image"+props.selectedPixdlePuzzleNumber+"guess5pixel"+40}
                                src={require(`../../dailyData/${props.selectedPixdlePuzzleNumber}/todaysImage.jpg`)} 
                                pixelSize={pixelSizeLevels[4]}
                />,
                <ImagePixelated key={"image"+props.selectedPixdlePuzzleNumber+"guess6pixel"+15}
                                src={require(`../../dailyData/${props.selectedPixdlePuzzleNumber}/todaysImage.jpg`)} 
                                pixelSize={pixelSizeLevels[5]}
                />,
                <ImagePixelated   key={"image"+props.selectedPixdlePuzzleNumber+"cleanpixel0"}
                                        src={require(`../../dailyData/${props.selectedPixdlePuzzleNumber}/todaysImage.jpg`)} 
                                        pixelSize={0}
                />                                                                                                          
            ]
        )
    

    },[props.selectedPixdlePuzzleNumber, pixelSizeLevels])  

    let picsStack = []
    for (let i = 0; i < imagePixelated.length; i++) {
        const zIndex = imagePixelated.length - i
        let isDisplayed = false

        if (props.gameOver && i === imagePixelated.length - 1){
            isDisplayed = true
        }else if (!props.gameOver){
            if (i === props.previousGuesses.length){
                isDisplayed = true
            }
        }

        if (isDisplayed){
            picsStack.push(<div key={"imageDiv"+props.selectedPixdlePuzzleNumber+"imageNumber"+zIndex} className='ImageObject' style={{zIndex: zIndex}}>
                                {imagePixelated[i]}
                            </div>)
        }else{
            picsStack.push(<div key={"imageDiv"+props.selectedPixdlePuzzleNumber+"imageNumber"+zIndex} className='ImageObject' style={{zIndex: zIndex, display:"none"}}>
                                {imagePixelated[i]}
                            </div>)
        }
    }

    return (
        <div className="ImageDiv">
            <div className='ImageContainer'>
                {picsStack}
            </div>
            <div className='PrevGuessSection'>
                <PreviousGuess previousGuesses={props.previousGuesses} actualAnswer={props.actualAnswer}/>
            </div>
        </div>
    )
})

export default ImageArea

{/* <ImagePixelated src={"../../todays-image/todays-image.png"} /> */}

    // const [pixelSize, setPixelSize] = useState(50)

    // useEffect(() =>{
    //     const pixelSizeLevels = [50, 40, 30, 20, 10, 5]

    //     if (props.gameOver){
    //         setPixelSize(0)
    //         //console.log("THIS IS RUNNING TO RESET PIXEL SIZE to 0")
    //     } else{
    //         if (props.previousGuesses.length < pixelSizeLevels.length){
    //             setPixelSize(pixelSizeLevels[props.previousGuesses.length])
    //             //console.log("THIS IS RUNNING TO RESET PIXEL SIZE to 0", pixelSizeLevels[props.previousGuesses.length])
    //         } 
    //     }
    // }, [props.gameOver, props.previousGuesses, props.selectedPixdlePuzzleNumber])

    // console.log("RENDERING")

    // const [imageLoaded, setImageLoaded] = useState(<ImagePixelated key={"image"+props.selectedPixdlePuzzleNumber+"guess"+props.previousGuesses.length+"pixel"+50}
    //                                                     src={require(`../../dailyData/${props.selectedPixdlePuzzleNumber}/todaysImage.jpg`)} 
    //                                                     pixelSize={50}
    //                                                 />)

    // let pixelSize = 50
    // const pixelSizeLevels = [50, 40, 30, 20, 10, 5]

    // if (props.gameOver){
    //     pixelSize = 0
    //     const newImage = <ImagePixelated key={"image"+props.selectedPixdlePuzzleNumber+"guess"+props.previousGuesses.length+"pixel"+pixelSize}
    //                         src={require(`../../dailyData/${props.selectedPixdlePuzzleNumber}/todaysImage.jpg`)} 
    //                         pixelSize={pixelSize}
    //                     />
    //     setTimeout(()=>{
    //         setImageLoaded(newImage)
    //     },250)                  

    //     //console.log("THIS IS RUNNING TO RESET PIXEL SIZE to 0")
    // } else{
    //     if (props.previousGuesses.length < pixelSizeLevels.length){
    //         pixelSize=pixelSizeLevels[props.previousGuesses.length]
    //         const newImage = <ImagePixelated key={"image"+props.selectedPixdlePuzzleNumber+"guess"+props.previousGuesses.length+"pixel"+pixelSize}
    //                                 src={require(`../../dailyData/${props.selectedPixdlePuzzleNumber}/todaysImage.jpg`)} 
    //                                 pixelSize={pixelSize}
    //                             />
    //         setTimeout(()=>{
    //         setImageLoaded(newImage)
    //         },250)  
    //         //console.log("THIS IS RUNNING TO RESET PIXEL SIZE to", pixelSizeLevels[props.previousGuesses.length])
    //     } 
    // }

    // console.log("RENDERING IMAGE FOR ", props.selectedPixdlePuzzleNumber)
    // console.log(props.gameOver, props.previousGuesses, pixelSize)

    //const imageToPixelate = require(`../../dailyData/${props.selectedPixdlePuzzleNumber}/todaysImage.jpg`)  
                        {/* <ImagePixelated key={"image"+props.selectedPixdlePuzzleNumber+"guess"+props.previousGuesses.length+"pixel"+pixelSize}
                                    src={require(`../../dailyData/${props.selectedPixdlePuzzleNumber}/todaysImage.jpg`)} 
                                    pixelSize={pixelSize}
                    />     */} 


                        // const [imageClean, setImageClean] = useState(<ImagePixelated    key={"image"+props.selectedPixdlePuzzleNumber+"cleanpixel0"}
    //                                                                 src={require(`../../dailyData/${props.selectedPixdlePuzzleNumber}/todaysImage.jpg`)} 
    //                                                                 pixelSize={0}
    //                                             /> )

    // useEffect(()=>{
    //     let pixelSize = 200
    //     const pixelSizeLevels = [180, 140, 100, 70, 40, 15]
    
    //     if (props.gameOver){
    //         pixelSize = 0
    //         const newImage = <ImagePixelated key={"image"+props.selectedPixdlePuzzleNumber+"guess"+props.previousGuesses.length+"pixel"+pixelSize}
    //                             src={require(`../../dailyData/${props.selectedPixdlePuzzleNumber}/todaysImage.jpg`)} 
    //                             pixelSize={pixelSize}
    //                         />
    //         setTimeout(()=>{
    //             setImageLoaded(newImage)
    //         },500)                  
    
    //     } else{
    //         if (props.previousGuesses.length < pixelSizeLevels.length){
    //             pixelSize=pixelSizeLevels[props.previousGuesses.length]
    //             const newImage = <ImagePixelated key={"image"+props.selectedPixdlePuzzleNumber+"guess"+props.previousGuesses.length+"pixel"+pixelSize}
    //                                     src={require(`../../dailyData/${props.selectedPixdlePuzzleNumber}/todaysImage.jpg`)} 
    //                                     pixelSize={pixelSize}
    //                                 />                   
    //             setTimeout(()=>{
    //                 setImageLoaded(newImage)
    //             },500)  
    //         } 
    //     }
    // },[props.gameOver, props.previousGuesses, props.selectedPixdlePuzzleNumber])   