import pixdleLogo from '../../images/pixdle-logo.png'
import questionMark from '../../images/question-circle.png'
import barChart from '../../images/chart-bar.png'
import './titleArea.css';
import { MenuItem, Select } from '@mui/material';
import PuzzleDropdown from './puzzleDropdown';





export default function TitleArea(props: any) {
    //Extra classes for MUI dropdown

    // Day Dropdown

    // How To Button
    function clickedHowToPlay(){
        props.dispatch({type: "OPEN_HOW_TO"})
    }

    // Results Area
    function clickedStats(){
        props.dispatch({type: "OPEN_STATS"})
    }

    return (
        <div className='TitleDiv'>
                <div className='PixdleLogoDiv'>
                    <img
                        src={pixdleLogo}
                        alt='PixdleLogo'
                        className="TitleImageObject"
                    />
                </div>
                <div className='DropdownDiv'>
                    <PuzzleDropdown dropDownNumbers={props.dropDownNumbers}
                                    dispatch={props.dispatch}
                                    currentSelected={props.currentSelected}
                                    />
                </div>
                <div className='IconDiv'onClick={clickedHowToPlay}>
                    <img
                        src={questionMark}
                        alt='HowToPlayButton'
                        className="IconObject"
                    />
                </div>
                <div className='IconDiv' onClick={clickedStats}>
                    <img
                        src={barChart}
                        alt='StatsButton'
                        className="IconObject"
                    />
                </div>   
        </div>
    )
}

{/* <div className='TitleDiv'>
<div className='PixdleLogoDiv'>
    <img
        src={pixdleLogo}
        alt='PixdleLogo'
        className="TitleImageObject"
    />   
</div>
<div className='HelperIconDiv'>
    <div className='DropdownDiv'>
        <PuzzleDropdown dropDownNumbers={props.dropDownNumbers}
                        dispatch={props.dispatch}
                        currentSelected={props.currentSelected}
                        />
    </div>
    <div className='IconDiv'onClick={clickedHowToPlay}>
        <img
            src={questionMark}
            alt='HowToPlayButton'
            className="IconObject"
        />
    </div>
    <div className='IconDiv' onClick={clickedStats}>
        <img
            src={barChart}
            alt='StatsButton'
            className="IconObject"
        />
    </div>
</div>
</div> */}