import React, { useEffect, useState } from 'react'
import './keyboard.css'
// //@ts-ignore
// export default function Keypad({ usedKeys}) {

//   const letters: any[] = []

//   return (
//     <div className="keypad">
//       {letters && letters.map(l => {
//         const color = usedKeys[l.key]
//         return (
//           <div key={l.key} className={color}>{l.key}</div>
//         )
//       })}
//     </div>
//   )
// }

export default function Keyboard(props: any) {
    const topRow = ["Q","W","E","R","T","Y","U","I","O","P"]
    const middleRow = ["A","S","D","F","G","H","J","K","L"]
    const bottomRow = ["Z","X","C","V","B","N","M"]
  
    return (
      <div className='keyboard not-selectable'>
        {topRow.map((letter) => {
            const color = props.usedKeys[letter]
            return (
                <div key={letter} className={color} onClick={()=>{props.updateUserGuess(letter)}}>{letter}</div>
            )
        })}
        <br/>
        {middleRow.map((letter) => {
            const color = props.usedKeys[letter]
            return (
                <div key={letter} className={color}  onClick={()=>{props.updateUserGuess(letter)}}>{letter}</div>
            )
        })}
        <br/>
        {/* <div key="Enter" style={{width: "15%", fontSize:"13px"}}>Enter</div> */}
        {bottomRow.map((letter) => {
            const color = props.usedKeys[letter]
            return (
                <div key={letter} className={color}  onClick={()=>{props.updateUserGuess(letter)}}>{letter}</div>
            )
        })}
        <div key="Backspace" style={{width: "10%"}}  onClick={()=>{props.handleBackspace()}}>⌫</div>
      </div>
    )
  }