import { useEffect, useState } from "react";
import { createPreviousUserDataBoxes, createSubmittedUserDataBoxes } from "./userGuessUtils";
import WordSizeBar from "./wordSizeBar";

export default function PreviousGuess(props: any) {
    
    const [previousGuessRows, setPreviousGuessRows] = useState<any[]>([])

    useEffect(() =>{
        let previousGuessRowsHelper: any[] = []

        for (let i = 0; i < props.previousGuesses.length; i++) {
            const previousUserGuess = props.previousGuesses[i]
    
            const userDataBoxes = createPreviousUserDataBoxes(previousUserGuess, props.actualAnswer, i)
    
            previousGuessRowsHelper.push(<div key={"guess"+i+"container"} className="guess-container">
                                                <div className='letter-row-div'>
                                                    {userDataBoxes}
                                                </div>
                                                <div className='letter-row-div-bar'>
                                                    <WordSizeBar currentGuess={previousUserGuess} actualAnswer={props.actualAnswer}/>
                                                </div>
                                        </div>)
        }

        setPreviousGuessRows(previousGuessRowsHelper)
    }, [props.actualAnswer, props.previousGuesses])



    return (
        <div className='user-guess-div'>
            {previousGuessRows}
        </div>
    )
}