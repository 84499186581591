import LinearProgress from '@mui/material/LinearProgress';

const styles = {
    colorPrimary: {
      backgroundColor: '#00695C',
    },
    barColorPrimary: {
      backgroundColor: '#B2DFDB',
    }
  };

export default function WordSizeBar(props: any) {
    
    const percentage = (props.currentGuess.length / 12) * 100 //Divid by max answer length

    let colorBar = ""
    if (props.currentGuess.length === props.actualAnswer.length){
        colorBar = "green"
    } else if (props.currentGuess.length < props.actualAnswer.length){
        colorBar = "darkorange"
    } else {
        colorBar = "red"
    }
    
    return (
            <LinearProgress variant="determinate" value={percentage} style={{ width: "100%"}} sx={{
                backgroundColor: 'transparent',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: colorBar
                }
              }}/>
    )
}