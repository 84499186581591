import { useState } from "react";
import UserGuess from "./userGuess";
import PreviousGuess from "./previousGuess";
import './userGuess.css';
import './inputArea.css'
import { Alert } from "@mui/material";
import Keyboard from "../keyboard/Keyboard";

export default function InputArea(props: any) {
    //const [previousGuesses, setPreviousGuesses] = useState<string[]>([])
    //const [gameOver, setGameOver] = useState(false)
    //const [winner, setWinner] = useState(false)

    const [alreadyGuessed, setAlreadyGuessed] = useState(false)

    function updateWithNewGuess(newGuess: string){
        if (props.previousGuesses.includes(newGuess)){
            // Warning user if guessing the same thing
            setAlreadyGuessed(true)

            setTimeout(()=>{
                setAlreadyGuessed(false)
            }, 3000)
        } else {
            //@ts-ignore
            // props.setPreviousGuesses((prevState) =>{
            //     return [...prevState, newGuess]
            // })
            props.dispatch({type: "UPDATE_GUESSES", newGuessValue: newGuess})
            props.dispatch({type: "CHECK_WINNER"})

            // Adding a new guess
            // if (newGuess.toUpperCase() === props.actualAnswer.toUpperCase()){
            //     // Winner
            //     props.setGameOver(true)
            //     props.setWinner(true)
            // } else if (props.previousGuesses.length === 5){
            //     // Loser
            //     props.setGameOver(true)
            // }
        }

    }

    return (
        <div className="inputArea-div">
            {/* Renders guessing area while game is going on */}
            {!props.gameOver &&
                    <UserGuess updateWithNewGuess={updateWithNewGuess} keyboardColors={props.keyboardColors} actualAnswer={props.actualAnswer}/>
            }
            
            {/* Render Correct Answer if loser with message */}
            {props.gameOver && !props.winner &&
                <div className="guess-container">
                    <p className="loss-text">Answer: </p>
                    <PreviousGuess previousGuesses={[props.actualAnswer]} actualAnswer={props.actualAnswer}/>
                    <p className="loss-text">New Puzzle Everyday at Midnight!</p>
                </div>
            }
            
            {/* Render Good Job if winner */}
            {props.gameOver && props.winner &&
                <div>
                    <p className="win-text">Good Job!</p>
                    <p className="win-text">New Puzzle Everyday at Midnight!</p>
                </div>

            }

            {/* Will always render user previous guesses */}
            {/* <div>
                <PreviousGuess previousGuesses={props.previousGuesses} actualAnswer={props.actualAnswer}/>
            </div> */}

            {/* Code for alerting user to already guessing something */}
            {alreadyGuessed &&
                <div className="alert-div">
                    <Alert severity="error">That has already been guessed</Alert>
                </div>
            }
            
        </div>
    )
}