import { MenuItem, Select } from "@mui/material"

export default function PuzzleDropdown(props: any) {

        // Populates menu
        let menuItems: any[] = []
        for (let i = props.dropDownNumbers; i > 0; i--) {
            menuItems.push(<MenuItem value={i}>Pixdle {i}</MenuItem>)
        } 

        return (
            <Select
            sx={{   color:"white",
                    '& .MuiSvgIcon-root': {
                        color: 'white',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',
                    }
            }}
            style={{maxHeight:"70%"}}
            notched={false}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.currentSelected}
            label="Age"
            //onChange={(e) => {props.dispatch({type: "CHANGE_SELECTED_PUZZLE", newPuzzleNumber: e.target.value })}}
            onChange={(e) => {props.dispatch({type: "LOAD_DAY_DATA", puzzleNumberSelected:  e.target.value})}}
            onClose={() => {
                setTimeout(() => {
                  //@ts-ignore
                  document.activeElement.blur();
                }, 0);
              }}
            >
            {menuItems}
        </Select>
        )
}