import UserInputBox from "./userInputBox"


export function createUserDataBoxes(currentGuess: string){
    let userDataBoxes: any[] = []
    
    for (let i = 0; i < currentGuess.length; i++) {
        userDataBoxes.push(<UserInputBox    key={"guess"+i}
                                            currentValue={currentGuess[i]} 
                            />)
    };

    if (currentGuess.length === 12){
        userDataBoxes[11] = (<UserInputBox  key="newGuess"
                                            currentValue={currentGuess[11]} 
                    />)
    } else if (currentGuess.length < 12){
        // This key needs to be like this, or at least unique, or the rendering will get confused
        userDataBoxes.push(<UserInputBox    key="newGuess"
                                            currentValue={""} 
                            />)
    }

    return userDataBoxes
}

export function createSubmittedUserDataBoxes(currentGuess: string, actualAnswer: string){
    let userSubmittedBoxes: any[] = []

    const currentGuessUpper = currentGuess.toUpperCase()
    const actualAnswerUpper = actualAnswer.toUpperCase()

    const chars = actualAnswerUpper.split('')

    for (let i = 0; i < currentGuessUpper.length; i++) {
        if (currentGuessUpper.charAt(i) === actualAnswerUpper.charAt(i)){
            // Remove letter from needed letters for correct answer
            const indexToRemoveInChars = chars.indexOf(currentGuessUpper.charAt(i))
            chars.splice(indexToRemoveInChars, 1) // need to specify the 1
        }
    }


    for (let i = 0; i < currentGuessUpper.length; i++) {
        let keyHelper = "guess" + i
        if (i === 11){
            keyHelper = "newGuess"
        }
        
        // Letter in correct position
        if (currentGuessUpper.charAt(i) === actualAnswerUpper.charAt(i)){
            // Letter in correct position
            userSubmittedBoxes.push(<UserInputBox   key={keyHelper}
                                                    currentValue={currentGuessUpper[i]} 
                                                    answer={"correctOnSubmission"} 
                                        />)

        } else if (chars.includes(currentGuessUpper.charAt(i))) {
            // Letter is still needed in word, but in another position
            userSubmittedBoxes.push(<UserInputBox   key={keyHelper}
                                                    currentValue={currentGuessUpper[i]} 
                                                    answer={"almostOnSubmission"}
                                                    />)                                                    
        } else {
            // Letter is not in word
            userSubmittedBoxes.push(<UserInputBox   key={keyHelper}
                                                    currentValue={currentGuessUpper[i]} 
                                                    answer={"incorrectOnSubmission"} 
                                                    />)  
        }
    }

    //else if ((actualAnswerUpper.indexOf(currentGuessUpper.charAt(i)) !== -1) && chars.includes(currentGuessUpper.charAt(i)))

    return userSubmittedBoxes
}

export function createPreviousUserDataBoxes(currentGuess: string, actualAnswer: string, guessNumber: number){
    let userSubmittedBoxes: any[] = []

    const currentGuessUpper = currentGuess.toUpperCase()
    const actualAnswerUpper = actualAnswer.toUpperCase()

    const chars = actualAnswerUpper.split('')

    for (let i = 0; i < currentGuessUpper.length; i++) {
        if (currentGuessUpper.charAt(i) === actualAnswerUpper.charAt(i)){
            // Remove letter from needed letters for correct answer
            const indexToRemoveInChars = chars.indexOf(currentGuessUpper.charAt(i))
            chars.splice(indexToRemoveInChars, 1) // need to specify the 1
        }
    }

    for (let i = 0; i < currentGuessUpper.length; i++) {
        // Letter in correct position
        if (currentGuessUpper.charAt(i) === actualAnswerUpper.charAt(i)){
            // Letter in correct position
            userSubmittedBoxes.push(<UserInputBox   key={"guess" + guessNumber + "char" + i}
                                                    currentValue={currentGuessUpper[i]} 
                                                    answer={"correct"} 
                                        />)

        } else if (chars.includes(currentGuessUpper.charAt(i))) {
            // Letter is still needed in word, but in another position
            userSubmittedBoxes.push(<UserInputBox   key={"guess" + guessNumber + "char" + i}
                                                    currentValue={currentGuessUpper[i]} 
                                                    answer={"almost"}
                                                    />)                                                    
        } else {
            // Letter is not in word
            userSubmittedBoxes.push(<UserInputBox   key={"guess" + guessNumber + "char" + i}
                                                    currentValue={currentGuessUpper[i]} 
                                                    answer={"incorrect"} 
                                                    />)  
        }
    }

    //else if ((actualAnswerUpper.indexOf(currentGuessUpper.charAt(i)) !== -1) && chars.includes(currentGuessUpper.charAt(i)))

    return userSubmittedBoxes
}


// export function createUserDataBoxes(currentGuess: string, updateUserGuess: any, handleBackspace: any, userSubmittedAnswer : any){
//     let userDataBoxes: any[] = []
    
//     for (let i = 0; i < currentGuess.length; i++) {
//         userDataBoxes.push(<UserInputBox    key={"guess"+i}
//                                             currentValue={currentGuess[i]} 
//                                             isDisabled={true}
//                                             isAutofocus={false} 
//                             />)
//     };

//     if (currentGuess.length === 12){
//         userDataBoxes[11] = (<UserInputBox  key="newGuess"
//                                             currentValue={currentGuess[11]} 
//                                             updateUserGuess={updateUserGuess} 
//                                             handleBackspace={handleBackspace}
//                                             userSubmittedAnswer={userSubmittedAnswer} 
//                                             isDisabled={false} 
//                                             isAutofocus={true}
//                     />)
//     } else if (currentGuess.length < 12){
//         // This key needs to be like this, or at least unique, or the rendering will get confused
//         userDataBoxes.push(<UserInputBox    key="newGuess"
//                                             currentValue={""} 
//                                             updateUserGuess={updateUserGuess} 
//                                             handleBackspace={handleBackspace}
//                                             userSubmittedAnswer={userSubmittedAnswer} 
//                                             isDisabled={false} 
//                                             isAutofocus={true}
//                             />)
//     }

//     return userDataBoxes
// }

// export function createSubmittedUserDataBoxes(currentGuess: string, actualAnswer: string){
//     let userSubmittedBoxes: any[] = []

//     const currentGuessUpper = currentGuess.toUpperCase()
//     const actualAnswerUpper = actualAnswer.toUpperCase()

//     const chars = actualAnswerUpper.split('')

//     for (let i = 0; i < currentGuessUpper.length; i++) {
//         if (currentGuessUpper.charAt(i) === actualAnswerUpper.charAt(i)){
//             // Remove letter from needed letters for correct answer
//             const indexToRemoveInChars = chars.indexOf(currentGuessUpper.charAt(i))
//             chars.splice(indexToRemoveInChars, 1) // need to specify the 1
//         }
//     }

//     for (let i = 0; i < currentGuessUpper.length; i++) {
//         // Letter in correct position
//         if (currentGuessUpper.charAt(i) === actualAnswerUpper.charAt(i)){
//             // Letter in correct position
//             userSubmittedBoxes.push(<UserInputBox   key={"guess"+i}
//                                                     currentValue={currentGuessUpper[i]} 
//                                                     isDisabled={true}
//                                                     isAutofocus={false}
//                                                     answer={"correctOnSubmission"} 
//                                         />)

//         } else if (chars.includes(currentGuessUpper.charAt(i))) {
//             // Letter is still needed in word, but in another position
//             userSubmittedBoxes.push(<UserInputBox   key={"guess"+i}
//                                                     currentValue={currentGuessUpper[i]} 
//                                                     isDisabled={true}
//                                                     isAutofocus={false}
//                                                     answer={"almostOnSubmission"}
//                                                     />)                                                    
//         } else {
//             // Letter is not in word
//             userSubmittedBoxes.push(<UserInputBox   key={"guess"+i}
//                                                     currentValue={currentGuessUpper[i]} 
//                                                     isDisabled={true}
//                                                     isAutofocus={false}
//                                                     answer={"incorrectOnSubmission"} 
//                                                     />)  
//         }
//     }

//     //else if ((actualAnswerUpper.indexOf(currentGuessUpper.charAt(i)) !== -1) && chars.includes(currentGuessUpper.charAt(i)))

//     return userSubmittedBoxes
// }

// export function createPreviousUserDataBoxes(currentGuess: string, actualAnswer: string, guessNumber: number){
//     let userSubmittedBoxes: any[] = []

//     const currentGuessUpper = currentGuess.toUpperCase()
//     const actualAnswerUpper = actualAnswer.toUpperCase()

//     const chars = actualAnswerUpper.split('')

//     for (let i = 0; i < currentGuessUpper.length; i++) {
//         if (currentGuessUpper.charAt(i) === actualAnswerUpper.charAt(i)){
//             // Remove letter from needed letters for correct answer
//             const indexToRemoveInChars = chars.indexOf(currentGuessUpper.charAt(i))
//             chars.splice(indexToRemoveInChars, 1) // need to specify the 1
//         }
//     }

//     for (let i = 0; i < currentGuessUpper.length; i++) {
//         // Letter in correct position
//         if (currentGuessUpper.charAt(i) === actualAnswerUpper.charAt(i)){
//             // Letter in correct position
//             userSubmittedBoxes.push(<UserInputBox   key={"guess" + guessNumber + "char" + i}
//                                                     currentValue={currentGuessUpper[i]} 
//                                                     isDisabled={true}
//                                                     isAutofocus={false}
//                                                     answer={"correct"} 
//                                         />)

//         } else if (chars.includes(currentGuessUpper.charAt(i))) {
//             // Letter is still needed in word, but in another position
//             userSubmittedBoxes.push(<UserInputBox   key={"guess" + guessNumber + "char" + i}
//                                                     currentValue={currentGuessUpper[i]} 
//                                                     isDisabled={true}
//                                                     isAutofocus={false}
//                                                     answer={"almost"}
//                                                     />)                                                    
//         } else {
//             // Letter is not in word
//             userSubmittedBoxes.push(<UserInputBox   key={"guess" + guessNumber + "char" + i}
//                                                     currentValue={currentGuessUpper[i]} 
//                                                     isDisabled={true}
//                                                     isAutofocus={false}
//                                                     answer={"incorrect"} 
//                                                     />)  
//         }
//     }

//     //else if ((actualAnswerUpper.indexOf(currentGuessUpper.charAt(i)) !== -1) && chars.includes(currentGuessUpper.charAt(i)))

//     return userSubmittedBoxes
// }