import {useEffect, useState } from 'react';
import './userInputBox.css';

export default function UserInputBox(props: any) {

    // Info for box value, disabled, focus
    const [currentBoxInfo, setCurrentBoxInfo] = useState("")

    useEffect(()=>{
        setCurrentBoxInfo(props.currentValue)
    }, [props.currentValue])


    let classNameForBox = "input-box input-box-normal"
    // Check for different background colors
    if (props.answer === "correct"){
        classNameForBox="input-box input-box-correct"
    } else if (props.answer === "almost"){
        classNameForBox="input-box input-box-almost"
    } else if (props.answer === "incorrect"){
        classNameForBox="input-box input-box-incorrect"
    }  else if (props.answer === "correctOnSubmission"){
        classNameForBox="input-box input-box-correct transition"
    } else if (props.answer === "almostOnSubmission"){
        classNameForBox="input-box input-box-almost transition"
    } else if (props.answer === "incorrectOnSubmission"){
        classNameForBox="input-box input-box-incorrect transition"
    }

    // If letterbox is disabled as input
    let letterBox = <input 
                        inputMode='none'
                        type="text" 
                        maxLength={1}
                        value={currentBoxInfo}
                        disabled={true}
                        className={classNameForBox}
                    />
    
    return (
            letterBox
    )
}

    // Info for box value, disabled, focus
    // const [currentBoxInfo, setCurrentBoxInfo] = useState({
    //     currentValue: "",
    //     isDisabled: false,
    //     isAutofocus: true
    // })

    // useEffect(()=>{
    //     setCurrentBoxInfo({
    //         currentValue : props.currentValue,
    //         isDisabled : props.isDisabled,
    //         isAutofocus: props.isAutofocus
    //     })
    // }, [props.currentValue, props.isDisabled, props.isAutofocus])

    // used to focus cursor on input
    //const inputRef = useRef(null);

    // useEffect(() => {
    //     if (props.isAutofocus){

    //         //@ts-ignore
    //         inputRef.current.focus();
    //     }
    //   }, []);

    // // Makes sure user character is a letter
    // function validateUserCharacter(inputted :string) : void{
    //     const valueAscii = inputted.charCodeAt(0)

    //     if ((valueAscii >= 65 && valueAscii <= 90) || (valueAscii >= 97 && valueAscii <= 122)){
    //         props.updateUserGuess(inputted.toUpperCase())
    //     }
    // }

    // // Processes backspace on box
    // function checkForEnterBackspace(inputCharacter : KeyboardEvent) : void{
    //     if (inputCharacter.code === "Backspace"){
    //         props.handleBackspace()
    //     } else if(inputCharacter.code === "Enter"){
    //         props.userSubmittedAnswer()
    //     }
    // }

    // If box is current input
    // if(!currentBoxInfo.isDisabled){
    //     letterBox = <input
    //                     ref={inputRef} 
    //                     type="text" 
    //                     maxLength={1}
    //                     value={currentBoxInfo.currentValue}
    //                     onChange={(e) => {
    //                         validateUserCharacter(e.target.value)
    //                     }}
    //                     onKeyDown={(e) => {
    //                         checkForEnterBackspace(e)
    //                     }}
    //                     disabled={currentBoxInfo.isDisabled}
    //                     //autoFocus={currentBoxInfo.isAutofocus}
    //                     className="input-box input-box-normal"
    //                 />
    // } 

    // setcurrentBoxValue(props.currentValue)
    // setIsCurrentBoxDisabled(props.isDisabled)
    // setIsCurrentBoxAutofocus(props.isAutofocus)

    //console.log(document.activeElement)
    // const [currentBoxValue, setcurrentBoxValue] = useState("")
    // const [isCurrentBoxDisabled, setIsCurrentBoxDisabled] = useState(false)
    // const [isCurrentBoxAutofocus, setIsCurrentBoxAutofocus] = useState(true)

    // useEffect(()=>{
    //     setIsCurrentBoxDisabled(props.isDisabled)
    // }, [props.isDisabled])

    // useEffect(()=>{
    //     console.log(props)
    //     setIsCurrentBoxAutofocus(props.isAutofocus)
    // }, [props.isAutofocus])

