import { useState, useEffect, useCallback } from 'react';
import './userGuess.css';
import './inputArea.css';
import { Button } from '@mui/material';
import { createSubmittedUserDataBoxes, createUserDataBoxes } from './userGuessUtils';
import Keyboard from '../keyboard/Keyboard';


export default function UserGuess(props: any) {
    const [currentGuess, setCurrentGuess] = useState("")
    const [userHitSubmit, setUserHitSubmit] = useState(false)

    const updateUserGuess = useCallback((inputCharacter : string) => { 
        setCurrentGuess((prevState) => {
            if (prevState.length === 12) {
                return prevState
            }
            return prevState + inputCharacter
        })
    }, [])

    const handleBackspace = useCallback(() => {
        setCurrentGuess((prevState) => {
            if (prevState.length === 0){
                return prevState
            }else{
                const currentLength = prevState.length
                return prevState.slice(0, currentLength - 1)
            }
        })
    }, [])

    const userSubmittedAnswer = useCallback(() => {
        if (currentGuess.length > 0){
            setUserHitSubmit(true)

            setTimeout(()=>{
                props.updateWithNewGuess(currentGuess)
                setUserHitSubmit(false)
                setCurrentGuess("")
            }, 1000)
        }
    },[currentGuess, props.updateWithNewGuess])

    useEffect(() => {
        function handleKeyDown(e: any) {
            const letterCode = e.keyCode
            if (letterCode === 8 && !userHitSubmit){
                handleBackspace()
            } else if(letterCode === 13 && !userHitSubmit){
                userSubmittedAnswer()
            } else if(((letterCode >= 65 && letterCode <= 90) || (letterCode >= 97 && letterCode <= 122)) && !userHitSubmit){
                updateUserGuess(e.key.toUpperCase())
            }
        }

        document.addEventListener('keydown', handleKeyDown);
    
        // Don't forget to clean up
        return function cleanup() {
          document.removeEventListener('keydown', handleKeyDown);
        }
      }, [updateUserGuess, handleBackspace, userSubmittedAnswer, userHitSubmit]);

    let userDataBoxes: any[] = []
    if (userHitSubmit){
        userDataBoxes = createSubmittedUserDataBoxes(currentGuess, props.actualAnswer)
    } else{
        userDataBoxes = createUserDataBoxes(currentGuess)
    }

    return (
        <div className='user-guess-div'>
            <div className='user-guess-container'>
                <div>
                    <p className='enter-guess'>Enter Guess:</p>
                </div>
                <div className='letter-row-div'>
                    {userDataBoxes}
                </div>
                {/* <div className='letter-row-div'>
                    {userHitSubmit &&
                        <WordSizeBar currentGuess={currentGuess} actualAnswer={"lighthouse"}/>
                    }
                </div> */}
                <div className='submit-button-div'>
                    <Button size="small" variant="contained" onClick={userSubmittedAnswer}>Submit</Button>
                </div>
            </div>
            <div className='keyboard-div'>
                {!props.gameOver &&
                    <div>
                        <Keyboard usedKeys={props.keyboardColors} updateUserGuess={updateUserGuess} handleBackspace={handleBackspace}/>
                    </div>
                }
            </div>
        </div>
    )
}



    // const userDataBoxes = useRef([<UserInputBox     currentValue={""} 
    //                                                 updateUserGuess={updateUserGuess} 
    //                                                 handleBackspace={handleBackspace} 
    //                                                 isDisabled={false} 
    //                                                 isAutofocus={true}
    //                                 />])

    // function updateUserGuess(inputCharacter : string){
    //     const boxFinal = <UserInputBox currentValue={inputCharacter} isDisabled={true} isAutofocus={false}/>

    //     //@ts-ignore
    //     // setUserDataBoxes((prevState)=>{
    //     //     const prevLength = prevState.length
    //     //     const newArray = prevState.slice(0, prevLength - 1) 
    //     //     newArray.push(boxFinal)
    //     //     newArray.push(<UserInputBox currentValue={""} 
    //     //                                 updateUserGuess={updateUserGuess} 
    //     //                                 handleBackspace={handleBackspace} 
    //     //                                 isDisabled={false} 
    //     //                                 isAutofocus={true}
    //     //                                 />)
    //     //     return newArray
    //     // })
    //     const userDataBoxesLength = userDataBoxes.current.length
    //     const userDataBoxesNew = userDataBoxes.current.slice(0, userDataBoxesLength-1)
    //     userDataBoxesNew.push(boxFinal)
    //     userDataBoxesNew.push(<UserInputBox currentValue={""} 
    //                         updateUserGuess={updateUserGuess} 
    //                         handleBackspace={handleBackspace} 
    //                         isDisabled={false} 
    //                         isAutofocus={true}
    //                         />)
    //     userDataBoxes.current = userDataBoxesNew            


    //     setCurrentGuess((prevState) => {
    //         return prevState + inputCharacter
    //     })
    // }

    // function handleBackspace(){
    //     //@ts-ignore
    //     // setUserDataBoxes((prevState) => {
    //     //     const currentLength = prevState.length
            
    //     //     if (currentLength === 1){
    //     //         const emptyGuess = [<UserInputBox 
    //     //                             currentValue={""} 
    //     //                             updateUserGuess={updateUserGuess} 
    //     //                             handleBackspace={handleBackspace} 
    //     //                             isDisabled={false} 
    //     //                             isAutofocus={true}
    //     //                             />]
    //     //         return emptyGuess
    //     //     } else {
    //     //         const newArray = prevState.slice(0, currentLength - 2)
    //     //         newArray.push(<UserInputBox 
    //     //             currentValue={""} 
    //     //             updateUserGuess={updateUserGuess} 
    //     //             handleBackspace={handleBackspace} 
    //     //             isDisabled={false} 
    //     //             isAutofocus={true}
    //     //             />)  
    //     //         return newArray    
    //     //     }
    //     // })

    //     const currentLength = userDataBoxes.current.length
        
    //     if (currentLength === 1){
    //         console.log("HERE")
    //         const emptyGuess = [<UserInputBox 
    //                             currentValue={""} 
    //                             updateUserGuess={updateUserGuess} 
    //                             handleBackspace={handleBackspace} 
    //                             isDisabled={false} 
    //                             isAutofocus={true}
    //                             />]
    //         userDataBoxes.current = emptyGuess
    //     } else {
    //         let newArray = userDataBoxes.current.slice(0, currentLength - 2)
    //         newArray.push(<UserInputBox 
    //             currentValue={""} 
    //             updateUserGuess={updateUserGuess} 
    //             handleBackspace={handleBackspace} 
    //             isDisabled={false} 
    //             isAutofocus={true}
    //             />)  
    //         userDataBoxes.current = newArray
    //     }


    //     setCurrentGuess((prevState) => {
    //         if (prevState.length === 0){
    //             return prevState
    //         }else{
    //             const currentLength = prevState.length
    //             return prevState.slice(0, currentLength - 1)
    //         }
    //     })

    // }