import { useEffect, useLayoutEffect, useReducer, useState } from 'react';
import './App.css';
import ImageArea from './components/image/imageArea';
import InputArea from './components/input/inputArea';
import TitleArea from './components/title/titleArea';
import HowToPlay from './components/howto/howToPlay';
import { startingKeyboardColors, updateKeyboardColors } from './components/keyboard/KeyboardUtils';
import { initialPixdleState, reducer, getGameDateUtil } from './reducer/pixdle-reducer';
import StatsArea from './components/statsArea/statsArea';

function App() {
  const [pixdleGameState, dispatch] = useReducer(reducer, initialPixdleState);

  useEffect(()=>{

    dispatch({type: "LOAD_INIT", pixdlePuzzleNumber: getGameDateUtil()})
    dispatch({type: "LOAD_DAY_DATA", puzzleNumberSelected:getGameDateUtil()})

    const file = require("./dailyData/"+ getGameDateUtil() +"/todaysAnswer.txt")
    fetch(file)
    .then(response => response.text())
    .then(text => {
      dispatch({type: "SET_CORRECT_ANSWER", answerToSet: text})
    })

  },[])

  useEffect(()=>{
    //dispatch({type: "LOAD_DAY_DATA", puzzleNumberSelected:10})

    // Set answer for day
    const file = require("./dailyData/"+ pixdleGameState.selectedPixdlePuzzleNumber +"/todaysAnswer.txt")
    fetch(file)
    .then(response => response.text())
    .then(text => {
      dispatch({type: "SET_CORRECT_ANSWER", answerToSet: text})
    })

  }, [pixdleGameState.selectedPixdlePuzzleNumber])

  useEffect(()=>{
    if (Object.keys(pixdleGameState.pixdleData).length !== 0){
      localStorage.setItem("pixdleData", JSON.stringify(pixdleGameState.pixdleData))
    }
  }, [pixdleGameState.pixdleData])

  // Keyboard State
  const [keyboardColors, setKeyboardColors] = useState(startingKeyboardColors())
  useEffect(()=>{
    const newColorMap = updateKeyboardColors(pixdleGameState.previousGuesses, pixdleGameState.answerToday)
    setKeyboardColors(newColorMap)
  }, [pixdleGameState.previousGuesses,  pixdleGameState.answerToday])


  function exitHowToBox(){
    dispatch({type: "EXIT_HOW_TO"})
  }

  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <div className="App">
      <div className="MainPage" style={{width: size[0], height:size[1]}}>
        <div className='TitleArea'>
          <TitleArea  dispatch={dispatch} 
                      currentSelected={pixdleGameState.selectedPixdlePuzzleNumber} 
                      dropDownNumbers={pixdleGameState.totalPixdleDays} />
        </div>
        <div className='ImageArea'>
          <ImageArea  previousGuesses={pixdleGameState.previousGuesses}
                      actualAnswer={pixdleGameState.answerToday}
                      gameOver={pixdleGameState.gameOver}
                      selectedPixdlePuzzleNumber={pixdleGameState.selectedPixdlePuzzleNumber}
          />
        </div>
        <div className='InputArea'>
          <InputArea  actualAnswer={pixdleGameState.answerToday} 
                      previousGuesses={pixdleGameState.previousGuesses}
                      gameOver={pixdleGameState.gameOver}
                      winner={pixdleGameState.winner}
                      keyboardColors={keyboardColors}
                      dayNumber={pixdleGameState.dayNumber}
                      dispatch={dispatch}
                      />  
        </div>
        {pixdleGameState.howToBox &&
          <div className='HowToArea'>
            <HowToPlay exitHowToBox={exitHowToBox}/>
          </div>
        }
        {Object.keys(pixdleGameState.pixdleData).length !== 0 && pixdleGameState.statsOpen &&
          <div className='StatsArea'>
            <StatsArea pixdleData={pixdleGameState.pixdleData} dispatch={dispatch}/>
          </div>
        }
      </div>
    </div>
  );
}

export default App;


  // const [dayNumber, setDayNumber] = useState(dateOfProgram)
  // const [answerToday, setAnswerToday] = useState("lighthouse")

  // // Get any data needed for day
  // const [pixdleData, setPixdleData] = useState({})

  // // General Game State
  // const [previousGuesses, setPreviousGuesses] = useState<string[]>([])
  // const [gameOver, setGameOver] = useState(false)
  // const [winner, setWinner] = useState(false)
  // const [howToBox, setHowToBox] = useState(false)

 // Loading Game State
  // useEffect(()=>{
  //   // On first run of app, need to get data or init data to localStorage
  //   const storage = localStorage.getItem('pixdleData')
    
  //   if (!storage){
  //     // Load HOW TO PLAY on first time
  //     setHowToBox(true)

  //     // Need to create storage object
  //     const itemToStore = {
  //       winloss: {
  //         total: 0,
  //         win: 0,
  //         loss: 0,
  //         spread: [0, 0, 0, 0, 0, 0]
  //       },
  //       dayState:{
  //         [dayNumber]: {
  //           guesses: [],
  //           winner: false
  //         } 
  //       }
  //     }
  //     localStorage.setItem("pixdleData", JSON.stringify(itemToStore))
  //     setPixdleData(itemToStore)
  //   }
  //   else{
  //     const storedData = JSON.parse(storage)
  //     setPixdleData(storedData)

  //     // Get any previous guesses
  //     const guesses = storedData["dayState"][dayNumber]["guesses"]
  //     setPreviousGuesses(guesses)

  //     // Check if game is already won
  //     const alreadyWinner = storedData["dayState"][dayNumber]["winner"]
  //     setWinner(alreadyWinner)

  //     // Check if game is over
  //     // If already marked as a winner OR already max guesses have occured
  //     if (alreadyWinner || guesses.length === 6){
  //       setGameOver(true)
  //     }
  //   }

  // }, [dayNumber])

  // Update previous guesses in the local storage
  // useEffect(()=>{
  //   setPixdleData((prevState)=>{
  //     const returnValue = {...prevState, dayState: {
  //       //@ts-ignore
  //       ...prevState['dayState'],
  //       [dayNumber]:{
  //         //@ts-ignore
  //         ...prevState['dayState'][dayNumber],
  //         guesses: previousGuesses
  //       }

  //     }}

  //     localStorage.setItem('pixdleData', JSON.stringify(returnValue))
  //     return returnValue
  //   })
    

  // }, [previousGuesses])