export function startingKeyboardColors(){
    return {
        'A': '',
        'B': '',
        'C': '',
        'D': '',
        'E': '',
        'F': '',
        'G': '',
        'H': '',
        'I': '',
        'J': '',
        'K': '',
        'L': '',
        'M': '',
        'N': '',
        'O': '',
        'P': '',
        'Q': '',
        'R': '',
        'S': '',
        'T': '',
        'U': '',
        'V': '',
        'W': '',
        'X': '',
        'Y': '',
        'Z': '',
    }
}

export function updateKeyboardColors(previousGuesses: string[], actualAnswer: string){
    const freshColorMap: any = startingKeyboardColors()
    const actualAnswerUpper = actualAnswer.toUpperCase()
    
    for (let i = 0; i < previousGuesses.length; i++) {
        // Get each guess
        const previousGuessUpper = previousGuesses[i].toUpperCase()

        // Loop through character of each guess
        for (let j = 0; j < previousGuessUpper.length; j++) {
            // Case of letter in correct position
            if (previousGuessUpper.charAt(j) === actualAnswerUpper.charAt(j)){
                freshColorMap[previousGuessUpper.charAt(j)] = 'green'
            } else if(actualAnswerUpper.includes(previousGuessUpper.charAt(j)) && freshColorMap[previousGuessUpper.charAt(j)] !== 'green'){
                // Case for letter in word
                freshColorMap[previousGuessUpper.charAt(j)] = 'yellow'
            } else if (!actualAnswerUpper.includes(previousGuessUpper.charAt(j))){
                // Case for letter not in word
                freshColorMap[previousGuessUpper.charAt(j)] = 'black'
            }
        }

    }
    return freshColorMap 
}