
export function getGameDateUtil(){
    // Get day of app
    const startingDate = new Date("04/14/2024")
    const presentDate = new Date()
    let dateOfProgram = Math.floor(((presentDate.getTime() - startingDate.getTime()) / (1000 * 3600 * 24))) + 1 

    // Maxing out at 40 for now
    if (dateOfProgram > 40){
      dateOfProgram = 40
    }

    return dateOfProgram
}


export const initialPixdleState = {
    selectedPixdlePuzzleNumber: getGameDateUtil(),
    totalPixdleDays: getGameDateUtil(),
    answerToday: "",
    previousGuesses: [],
    gameOver: false,
    winner: false,
    howToBox: false,
    statsOpen: false,
    pixdleData: {}
};
  
export const reducer = (state: any, action: any) => { 
    //console.log(action)
    switch (action.type) { 
        case "LOAD_INIT": {
            const stateNew = {...state}
            const pixdlePuzzleNumber = action.pixdlePuzzleNumber
            stateNew.totalPixdleDays = pixdlePuzzleNumber
            stateNew.selectedPixdlePuzzleNumber = pixdlePuzzleNumber

            // On first run of app, need to get data or init data to localStorage
            const storage = localStorage.getItem('pixdleData')
            
            if (!storage){
                // Load HOW TO PLAY on first time
                stateNew.howToBox = true

                // Need to create storage object
                const itemToStore = {
                    winloss: {
                        total: 0,
                        win: 0,
                        loss: 0,
                        spread: [0, 0, 0, 0, 0, 0]
                    },
                    dayState:{
                        [pixdlePuzzleNumber]: {
                            guesses: [],
                            winner: false
                        } 
                    }
                }
                stateNew.pixdleData = itemToStore
            }else{
                const storedData = JSON.parse(storage)
                stateNew.pixdleData = storedData
            }
            return stateNew
        }
        case "LOAD_DAY_DATA":{
            const stateNew = {...state}
            const storedData= state.pixdleData

            const puzzleChangingTo =  action.puzzleNumberSelected

            stateNew.selectedPixdlePuzzleNumber = puzzleChangingTo

            // Update to add data for that day
            if(!(puzzleChangingTo in storedData["dayState"])){
                const newLocalData = {
                    ...state.pixdleData, 
                    dayState: {
                        ...state.pixdleData['dayState'],
                        [puzzleChangingTo]:{
                            guesses: [],
                            winner: false
                        } 
                    }}
                
                stateNew.previousGuesses = []
                stateNew.winner = false
                stateNew.gameOver = false
                stateNew.pixdleData = newLocalData
            }else{
                // Already have info for that day
                //const storedData = JSON.parse(storage)
                //stateNew.pixdleData = storedData

                // Get any previous guesses for that day
                const guesses = storedData["dayState"][puzzleChangingTo]["guesses"]
                stateNew.previousGuesses = guesses

                // Check if game is already won for that day
                const alreadyWinner = storedData["dayState"][puzzleChangingTo]["winner"]
                stateNew.winner = alreadyWinner

                // Check if game is over for that day
                // If already marked as a winner OR already max guesses have occured
                stateNew.gameOver = false
                if (alreadyWinner || guesses.length === 6){
                    stateNew.gameOver = true
                }
            }

            return stateNew
        }
        case "CHANGE_SELECTED_PUZZLE": {
            const stateNew = {...state}
            stateNew.selectedPixdlePuzzleNumber = action.newPuzzleNumber
            return stateNew
        }
        case "SET_CORRECT_ANSWER":{
            const stateNew = {...state}
            stateNew.answerToday = action.answerToSet
            return stateNew
        }
        case "UPDATE_GUESSES":{
            const stateNew = {...state}
            const newGuess = action.newGuessValue
            const newPrevGuesses = [...state.previousGuesses, newGuess]

            // Update current state
            stateNew.previousGuesses =newPrevGuesses

            const newLocalData = {
                ...state.pixdleData, 
                dayState: {
                    ...state.pixdleData['dayState'],
                    [state.selectedPixdlePuzzleNumber]:{
                        ...state.pixdleData['dayState'][state.selectedPixdlePuzzleNumber],
                        guesses: newPrevGuesses
                    }
              }}
            
            stateNew.pixdleData = newLocalData

            return stateNew
        }
        case "CHECK_WINNER":{
            // Update State
            const newState = {...state}

            if (state.previousGuesses.includes(state.answerToday.toUpperCase())){
                // Winner
                newState.winner = true
                newState.gameOver = true

                // Update Local Storage Info
                const oldTotal = state.pixdleData["winloss"]["total"]
                const oldWin = state.pixdleData["winloss"]["win"]
                
                // Update win spreads
                const newSpread = [...state.pixdleData["winloss"]["spread"]]
                const indexToUpdate = state.previousGuesses.length - 1
                const valueNeeded = newSpread[indexToUpdate]
                newSpread[indexToUpdate] = valueNeeded + 1


                const newLocalData = {
                    ...state.pixdleData,
                    winloss:{
                        ...state.pixdleData["winloss"],
                        total: oldTotal + 1,
                        win: oldWin + 1,
                        spread: newSpread
                    } ,
                    dayState: {
                        ...state.pixdleData['dayState'],
                        [state.selectedPixdlePuzzleNumber]:{
                            ...state.pixdleData['dayState'][state.selectedPixdlePuzzleNumber],
                            winner: true
                        }
                }}

                newState.pixdleData = newLocalData
            } else if (state.previousGuesses.length === 6){
                // Loser
                newState.gameOver = true

                const oldTotal = state.pixdleData["winloss"]["total"]
                const oldLoss = state.pixdleData["winloss"]["loss"]
                // Update Local Storage Info
                const newLocalData = {
                    ...state.pixdleData,
                    winloss:{
                        ...state.pixdleData["winloss"],
                        total: oldTotal + 1,
                        loss: oldLoss + 1
                    } ,
                    dayState: {
                        ...state.pixdleData['dayState'],
                        [state.selectedPixdlePuzzleNumber]:{
                            ...state.pixdleData['dayState'][state.selectedPixdlePuzzleNumber],
                            winner: false
                        }
                }}

                newState.pixdleData = newLocalData
            } 


            return newState
        }
        case "OPEN_HOW_TO":{
            const newState = {...state}
            newState.howToBox = true
            newState.statsOpen = false
            return newState
        }
        case "EXIT_HOW_TO":{
            const newState = {...state}
            newState.howToBox = false
            return newState
        }
        case "OPEN_STATS":{
            const newState = {...state}
            newState.howToBox = false
            newState.statsOpen = true
            return newState
        }
        case "EXIT_STATS":{
            const newState = {...state}
            newState.statsOpen = false
            return newState
        }
        default:
            return state;
        }
  };
  